import * as React from "react";

import {
  ImageBackground,
  Alert,
  Modal,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { Text, View } from "../components/Themed";
import { Icon } from "react-native-elements";
import { useState } from "react";
import DropDownPicker from "react-native-dropdown-picker";

export default function CaloriasRecomendadas() {
  const [altura, setAltura] = useState("");
  const [mascals, setMascals] = useState("");
  const [defvol, setDefvol] = useState("");
  const [peso, setPeso] = useState("");
  const [sexo, setSexo] = useState("Hombre");
  const [metodo, setMetodo] = useState("Volumen");
  var [resultadoCals, setResultadoCals] = useState(0);
  const [edad, setEdad] = useState("");
  const [actividad, setActividad] = useState("No quiero tener en cuenta esto");
  const [esMujer, setEsMujer] = useState(false);
  const [esVolumen, setesVolumen] = useState(false);
  const [modal, setModal] = useState(false);
  var [mensaje, setMensaje] = useState("");

  function isNumber(value: string | number): boolean {
    return value != null && value !== "" && !isNaN(Number(value.toString()));
  }

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openTipoDieta, setOpenTipoDieta] = useState(false);

  function cambiaSexo(sexo: string) {
    setSexo(sexo);
    sexo == "Hombre" ? setEsMujer(false) : setEsMujer(true);
  }

  function cambiaMetodo(metodo: string) {
    setMetodo(metodo);
    metodo == "Volumen" ? setesVolumen(false) : setesVolumen(true);
  }

  const [tipoDieta, setTipoDieta] = useState(1);

  // tipoDieta = 1 baja en grasas
  // tipoDieta = 2 baja en hidratos
  // tipoDieta = 3 keto

  function calculate() {
    if (!altura) {
      Alert.alert("Introduce una altura");
    } else if (!peso) {
      Alert.alert("Introduce un peso");
    } else if (!isNumber(peso) || !isNumber(edad) || !isNumber(altura)) {
      Alert.alert("Introduce valores numéricos");
    } else if (!edad) {
      Alert.alert("Introduce una edad");
    } else {
      var pesoInt = +peso;
      var mascalsInt = +mascals;
      var defvolInt = +defvol;
      var alturaInt = +altura;
      var edadInt = +edad;
      var ejercicioInt = 0;
      switch (actividad) {
        case "No quiero tener en cuenta esto":
          ejercicioInt = 1;
          break;
        case "Hago poco o nada de ejercicio":
          ejercicioInt = 1.2;
          break;
        case "Hago ejercicio de 1 a 3 veces por semana":
          ejercicioInt = 1.375;
          break;
        case "Hago ejercicio de 3 a 5 veces por semana":
          ejercicioInt = 1.55;
          break;
        case "Casi todos los días hago ejercicio":
          ejercicioInt = 1.72;
          break;
        case "Vivo por y para el ejercicio":
          ejercicioInt = 1.9;
          break;
      }

      if (esMujer) {
        var tmb = 10 * pesoInt + 6.25 * alturaInt - 5 * edadInt - 161;
      } else {
        var tmb = 10 * pesoInt + 6.25 * alturaInt - 5 * edadInt + 5;
      }

      var res = tmb * ejercicioInt;

      var prote = 0;
      var proteCals = 0;
      var grasas = 0;
      var grasasCals = 0;
      var carbosnoob = (res - (grasasCals + proteCals)) / 4;
      var calsComer = 0;

      if (actividad != "No quiero tener en cuenta esto") {
        setResultadoCals(res);
        setMensaje(
          "El número de calorías necesarias para mantener tu peso actual es " +
            res.toFixed(2) +
            ". Te recomiendo " +
            prote.toFixed(0) +
            "g de proteína, " +
            grasas.toFixed(0) +
            "g de grasas y " +
            carbosnoob.toFixed(0) +
            "g carbos"
        );
      } else {
        switch (tipoDieta) {
          // Tipo de dieta baja en grasas
          case 1:
            {
              var prote = pesoInt * 2.2;
              var proteCals = prote * 4;
              var grasas = pesoInt * 1;
              var grasasCals = grasas * 9;

              if (metodo == "Volumen") {
                var calsComer = res + mascalsInt + defvolInt;
                var carbosComer = (calsComer - (grasasCals + proteCals)) / 4;
              } else {
                var calsComer = res + mascalsInt - defvolInt;
                var carbosComer = (calsComer - (grasasCals + proteCals)) / 4;
              }
              {
                metodo == "Volumen"
                  ? setMensaje(
                      "Volumen/Dieta baja en grasas, superávit de " +
                        defvolInt +
                        " kcal. Comer= " +
                        calsComer.toFixed(2) +
                        " calorías" +
                        ". Te recomiendo " +
                        prote.toFixed(0) +
                        "g de proteína, " +
                        grasas.toFixed(0) +
                        "g de grasas y " +
                        carbosComer.toFixed(0) +
                        "g carbos"
                    )
                  : setMensaje(
                      "Definición/Dieta baja en grasas, déficit de " +
                        defvolInt +
                        " kcal. Comer= " +
                        calsComer.toFixed(2) +
                        " calorías" +
                        ". Te recomiendo " +
                        prote.toFixed(0) +
                        "g de proteína, " +
                        grasas.toFixed(0) +
                        "g de grasas y " +
                        carbosComer.toFixed(0) +
                        "g carbos"
                    );
              }
              setModal(true);
            }
            break;

          // Tipo de dieta baja en hidratos
          case 2:
            {
              var prote = pesoInt * 2.2;
              var proteCals = prote * 4;
              var grasasCals = grasas * 9;
              var carbosComer = pesoInt * 1.2;
              var carbosCals = carbosComer * 4;

              if (metodo == "Volumen") {
                var calsComer = res + mascalsInt + defvolInt;
                var grasasComer = (calsComer - (grasasCals + proteCals)) / 9;
              } else {
                var calsComer = res + mascalsInt - defvolInt;
                var grasasComer = (calsComer - (grasasCals + proteCals)) / 9;
              }

              {
                metodo == "Volumen"
                  ? setMensaje("No te recomiendo volumen con esta dieta ;) ")
                  : setMensaje(
                      "Definición/Dieta baja en hidratos, déficit de " +
                        defvolInt +
                        " kcal. Comer= " +
                        calsComer.toFixed(2) +
                        " calorías" +
                        ". Te recomiendo " +
                        prote.toFixed(0) +
                        "g de proteína, " +
                        grasasComer.toFixed(0) +
                        "g de grasas y " +
                        carbosComer.toFixed(0) +
                        "g carbos"
                    );
              }
              setModal(true);
            }
            break;
          // Tipo de dieta KETO
          case 3:
            {
              var prote = pesoInt * 2.2;
              var proteCals = prote * 4;
              var grasasCals = grasas * 9;
              var carbosComer = 40;
              var carbosCals = carbosComer * 4;

              if (metodo == "Volumen") {
                var calsComer = res + mascalsInt + defvolInt;
                var grasasComer = (calsComer - (grasasCals + proteCals)) / 9;
              } else {
                var calsComer = res + mascalsInt - defvolInt;
                var grasasComer = (calsComer - (grasasCals + proteCals)) / 9;
              }

              {
                metodo == "Volumen"
                  ? setMensaje("No te recomiendo volumen con esta dieta ;) ")
                  : setMensaje(
                      "Definición/Dieta KETO, déficit de " +
                        defvolInt +
                        " kcal. Comer= " +
                        calsComer.toFixed(2) +
                        " calorías" +
                        ". Te recomiendo " +
                        prote.toFixed(0) +
                        "g de proteína, " +
                        grasasComer.toFixed(0) +
                        "g de grasas y " +
                        carbosComer.toFixed(0) +
                        "g carbos"
                    );
              }
              setModal(true);
            }
            break;
        }
      }
    }
  }

  return (
    <ImageBackground
      style={common.imgBackground}
      resizeMode="cover"
      source={require("../assets/images/backi.png")}
    >
      <View style={common.content}>
        <View style={common.recuadroFondo}>
          <Text style={{ fontFamily: "Poppins", color: "black", fontSize: 18 }}>
            Calcular calorías para
            <Text
              style={{ color: "#36D993", fontFamily: "Poppins", fontSize: 18 }}
            >
              {" "}
              {sexo}
            </Text>
          </Text>
        </View>
        <View style={common.fila}>
          <View style={common.pickerview}>
            <DropDownPicker
              style={common.picker}
              value={sexo}
              open={open1}
              items={[
                { label: "Hombre", value: "Hombre" },
                { label: "Mujer", value: "Mujer" },
              ]}
              setOpen={setOpen1}
              setValue={(itemValue, itemIndex) => cambiaSexo(itemValue)}
              onChangeValue={(value) => {
                cambiaSexo(value);
              }}
              selectedValue={sexo}
            />
          </View>
          <View style={common.pickerview}>
            <DropDownPicker
              style={common.picker}
              value={tipoDieta}
              open={openTipoDieta}
              items={[
                { label: "Dieta baja en grasas", value: 1 },
                { label: "Dieta baja en hidratos", value: 2 },
                { label: "Dieta keto", value: 3 },
              ]}
              setOpen={setOpenTipoDieta}
              setValue={(itemValue, itemIndex) => setTipoDieta(itemValue)}
              onChangeValue={(value) => {
                setTipoDieta(value);
                console.log(tipoDieta);
              }}
              selectedValue={tipoDieta}
            />
          </View>
        </View>
        <View style={common.fila}>
          <View style={common.inputView}>
            <Text style={common.labelinput}>Altura (cm)</Text>
            <TextInput
              style={common.input}
              underlineColorAndroid="transparent"
              placeholder="188"
              placeholderTextColor={"#ababab"}
              autoCapitalize="none"
              value={altura}
              onChangeText={(text) => setAltura(text)}
            />
          </View>
          <View style={common.inputView}>
            <Text style={common.labelinput}>Peso (kg)</Text>
            <TextInput
              style={common.input}
              underlineColorAndroid="transparent"
              placeholder="87"
              placeholderTextColor={"#ababab"}
              autoCapitalize="none"
              value={peso}
              onChangeText={(text) => setPeso(text)}
            />
          </View>
          <View style={common.inputView}>
            <Text style={common.labelinput}>Edad (años)</Text>
            <TextInput
              style={common.input}
              underlineColorAndroid="transparent"
              placeholder="33"
              placeholderTextColor={"#ababab"}
              autoCapitalize="none"
              value={edad}
              onChangeText={(text) => setEdad(text)}
            />
          </View>
        </View>

        <View style={common.pickerview}>
          <Text style={common.labelinput}>
            Cuál es tu nivel de actividad física?
          </Text>
          <DropDownPicker
            style={common.picker}
            value={actividad}
            open={open2}
            items={[
              {
                label: "No quiero tener en cuenta esto",
                value: "No quiero tener en cuenta esto",
              },
              {
                label: "Hago poco o nada de ejercicio",
                value: "Hago poco o nada de ejercicio",
              },
              {
                label: "Hago ejercicio de 1 a 3 veces por semana",
                value: "Hago ejercicio de 1 a 3 veces por semana",
              },
              {
                label: "Hago ejercicio de 3 a 5 veces por semana",
                value: "Hago ejercicio de 3 a 5 veces por semana",
              },
              {
                label: "Casi todos los días hago ejercicio",
                value: "Casi todos los días hago ejercicio",
              },
              {
                label: "Vivo por y para el ejercicio",
                value: "Vivo por y para el ejercicio",
              },
            ]}
            setOpen={setOpen2}
            setValue={(itemValue, itemIndex) => setActividad(itemValue)}
            onChangeValue={(value) => {
              setActividad(value);
            }}
            selectedValue={actividad}
          />
        </View>

        <View
          style={
            actividad == "No quiero tener en cuenta esto"
              ? common.filaInferior
              : common.filaInferiorDesaparece
          }
        >
          <View style={{ backgroundColor: "rgba(0, 0, 0,0)", width: "35%" }}>
            <Text style={common.labelinput}>Estas en..</Text>
            <DropDownPicker
              style={common.picker}
              value={metodo}
              open={open3}
              items={[
                { label: "Volumen", value: "Volumen" },
                { label: "Definición", value: "Definición" },
              ]}
              setOpen={setOpen3}
              setValue={(itemValue, itemIndex) => cambiaMetodo(itemValue)}
              onChangeValue={(value) => {
                cambiaMetodo(value);
                console.log(metodo);
              }}
              selectedValue={metodo}
            />
          </View>
          <View style={{ backgroundColor: "rgba(0, 0, 0,0)", width: "25%" }}>
            <Text style={common.labelinput}>Kcal quemadas</Text>
            <TextInput
              style={common.input}
              underlineColorAndroid="transparent"
              placeholder="993"
              placeholderTextColor={"#ababab"}
              autoCapitalize="none"
              value={mascals}
              onChangeText={(text) => setMascals(text)}
            />
          </View>
          <View style={{ backgroundColor: "rgba(0, 0, 0,0)", width: "35%" }}>
            <Text numberOfLines={1} style={common.labelinput}>
              Kcal {esVolumen ? "deficit" : "superavit"}
            </Text>
            <TextInput
              style={common.input}
              underlineColorAndroid="transparent"
              placeholder="300"
              placeholderTextColor={"#ababab"}
              autoCapitalize="none"
              value={defvol}
              onChangeText={(text) => setDefvol(text)}
            />
          </View>
        </View>

        <TouchableOpacity style={common.button} onPress={() => calculate()}>
          <View style={common.buttonContent}>
            <Icon
              size={20}
              style={common.buttonIcon}
              type="material-community"
              name="calculator-variant"
            ></Icon>
            <Text style={common.buttonText}>Calcular</Text>
          </View>
        </TouchableOpacity>

        <Modal style={common.modal} transparent={true} visible={modal}>
          <View
            style={{
              backgroundColor: "rgba(0, 0, 0,0.4)",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                backgroundColor: "white",
                width: 300,
                height: "60%",
                padding: 20,
                borderRadius: 20,
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 22,
                  color: "black",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                {mensaje}
              </Text>
              <TouchableOpacity
                style={common.button}
                onPress={() => setModal(false)}
              >
                <View style={common.buttonContent}>
                  <Text style={common.buttonText}>Ok</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>
    </ImageBackground>
  );
}

const common = StyleSheet.create({
  modal: {},
  imgBackground: {
    width: "100%",
    height: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  fila: {
    marginVertical: 40,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "rgba(255, 255, 255,0)",
    alignContent: "center",
    justifyContent: "center",
  },
  filaInferior: {
    marginVertical: 25,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "rgba(255, 255, 255,0)",
    alignContent: "center",
    justifyContent: "space-between",
    maxWidth: "90%",
    zIndex: 1,
  },
  filaInferiorDesaparece: {
    display: "none",
  },

  recuadroFondo: {
    width: "90%",
    height: "10%",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255,0)",
    borderRadius: 20,
  },
  pickerview: {
    display: "flex",
    margin: 4,
    alignItems: "center",
    fontFamily: "Poppins",
    backgroundColor: "rgba(255, 255, 255,0)",
    width: "40%",
    borderRadius: 10,
    zIndex: 3, // works on ios
    elevation: 3, // works on android
  },

  picker: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "Poppins",
    height: 30,
    zIndex: 100,
    color: "#344953",
    backgroundColor: "white",
    justifyContent: "center",
    borderRadius: 10,
  },

  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
  },
  content: {
    display: "flex",
    width: "100%",
    flex: 1,
    flexDirection: "column",
    marginTop: "20%",
    alignItems: "center",
    backgroundColor: "rgba(157,220,243,0)",
  },
  labelinput: {
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: 14,
    color: "black",
  },
  inputView: {
    marginHorizontal: 10,
    backgroundColor: "rgba(255, 255, 255,0)",
    width: "28%",
  },

  input: {
    fontFamily: "Poppins",
    height: 30,
    width: "100%",
    textAlign: "center",
    backgroundColor: "white",
    borderWidth: 1,
    borderRadius: 15,
  },

  button: {
    position: "relative",
    alignItems: "center",
    marginTop: "20%",
    marginBottom: "10%",
  },
  buttonText: {
    fontFamily: "Poppins",
    color: "black",
    fontSize: 15,
    textAlignVertical: "center",
  },
  buttonIcon: {
    backgroundColor: "rgba(157,220,243,0)",
  },
  buttonContent: {
    justifyContent: "center",
    width: 160,
    borderColor: "#B2FFB7",
    borderRadius: 30,
    borderWidth: 4,
    padding: 10,
    flexDirection: "row",
    backgroundColor: "white",
  },
});
