import * as React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import { ImageBackground, Text, View } from "react-native";
import { Icon } from "react-native-elements";
import { StackScreenProps } from "@react-navigation/stack";
import { RootStackParamList } from "../types";

export default function TabOneScreen({
  navigation,
}: StackScreenProps<RootStackParamList>) {
  return (
    <ImageBackground
      style={styles.imgBackground}
      resizeMode="cover"
      source={require("../assets/images/backi.png")}
    >
      <View style={styles.gradient}>
        <TouchableOpacity
          style={styles.button}
          onPress={() => navigation.navigate("Historial")}
        >
          <View style={styles.buttonContent}>
            <Icon
              style={styles.buttonIcon}
              type="material-community"
              name="history"
            ></Icon>
            <Text style={styles.buttonText}>Historial</Text>
          </View>
        </TouchableOpacity>
      </View>

      <View style={styles.gradient}>
        <TouchableOpacity
          style={styles.button}
          onPress={() => navigation.navigate("CaloriasRecomendadas")}
        >
          <View style={styles.buttonContent}>
            <Icon
              style={styles.buttonIcon}
              type="material-community"
              name="fire"
            ></Icon>
            <Text style={styles.buttonText}>Calorías diarias recomendadas</Text>
          </View>
        </TouchableOpacity>
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  button: {
    alignItems: "center",
  },
  imgBackground: {
    width: "100%",
    height: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContent: {
    flexDirection: "row",
  },
  gradient: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#B2FFB7",
    borderRadius: 30,
    borderWidth: 4,
    backgroundColor: "white",
    height: 100,
    marginTop: 10,
    width: "80%",
  },
  buttonText: {
    fontSize: 20,
    textAlignVertical: "center",
  },
  buttonIcon: {
    color: "#B2FFB7",
  },
});
